import React from 'react';
import { Div, Group, Panel, PanelHeader, PanelHeaderBack, useAppearance } from '@vkontakte/vkui';
import './Rules.css'
import emoji from '../mechanics/emoji';
import lines_schema_white from '../img/lines_schema_white.png';
import lines_schema_dark from '../img/lines_schema_dark.png';

const Rules = props => {
    const appearance = useAppearance();
    return (
	<Panel id={props.id}>
		<PanelHeader
			before={<PanelHeaderBack onClick={props.go} data-to="home"/>}
		>
			Правила игры
		</PanelHeader>
        <Div className='main-container'>
            <div className='header-span-div'>
                <span className='text-span header-span'>В игре присутствует <strong className='header-span-bold'>20 выигрышных линий:</strong></span>
            </div>
            <img className='lines-img' src={(appearance === 'light') ? lines_schema_white : lines_schema_dark}/>
            <div className='header-span-div'>
                <span className='text-span header-span'>Все выплаты осуществляются <strong className='header-span-bold'>при выпадении символов непрерывно слева направо по выигрышным линиям</strong>, начиная с крайнего левого барабана.</span>
            </div>
            <div className='simple-elements-container'>
                <div className='simple-element-info'>
                    <img className='simple-element-img' src={emoji.static[0]}/>
                    <div className='info-texts-container'>
                        <span className='text-span x-text-span'>5 — <strong className='header-span-bold'>x37.5</strong></span>
                        <span className='text-span x-text-span'>4 — <strong className='header-span-bold'>x7.50</strong></span>
                        <span className='text-span x-text-span'>3 — <strong className='header-span-bold'>x2.50</strong></span>
                    </div>
                    
                </div>
                <div className='simple-element-info'>
                    <img className='simple-element-img' src={emoji.static[1]}/> 
                    <div className='info-texts-container'>
                        <span className='text-span x-text-span'>5 — <strong className='header-span-bold'>x25.0</strong></span>
                        <span className='text-span x-text-span'>4 — <strong className='header-span-bold'>x5.00</strong></span>
                        <span className='text-span x-text-span'>3 — <strong className='header-span-bold'>x1.75</strong></span>
                    </div>
                </div>
                <div className='simple-element-info'>
                    <img className='simple-element-img' src={emoji.static[2]}/>
                    <div className='info-texts-container'>
                        <span className='text-span x-text-span'>5 — <strong className='header-span-bold'>x15.0</strong></span>
                        <span className='text-span x-text-span'>4 — <strong className='header-span-bold'>x3.00</strong></span>
                        <span className='text-span x-text-span'>3 — <strong className='header-span-bold'>x1.25</strong></span>
                    </div>
                    
                </div>
                <div className='simple-element-info'>
                    <img className='simple-element-img' src={emoji.static[3]}/>
                    <div className='info-texts-container'>
                        <span className='text-span x-text-span'>5 — <strong className='header-span-bold'>x10.0</strong></span>
                        <span className='text-span x-text-span'>4 — <strong className='header-span-bold'>x2.00</strong></span>
                        <span className='text-span x-text-span'>3 — <strong className='header-span-bold'>x1.00</strong></span>
                    </div>
                </div>
                <div className='simple-element-info'>
                    <img className='simple-element-img' src={emoji.static[4]}/>
                    <div className='info-texts-container'>
                        <span className='text-span x-text-span'>5 — <strong className='header-span-bold'>x7.50</strong></span>
                        <span className='text-span x-text-span'>4 — <strong className='header-span-bold'>x1.25</strong></span>
                        <span className='text-span x-text-span'>3 — <strong className='header-span-bold'>x0.60</strong></span>
                    </div> 
                </div>
                <div className='simple-element-info'>
                    <img className='simple-element-img' src={emoji.static[5]}/>
                    <div className='info-texts-container'>
                        <span className='text-span x-text-span'>5 — <strong className='header-span-bold'>x5.00</strong></span>
                        <span className='text-span x-text-span'>4 — <strong className='header-span-bold'>x1.00</strong></span>
                        <span className='text-span x-text-span'>3 — <strong className='header-span-bold'>x0.40</strong></span>
                    </div>
                </div>
                <div className='simple-element-info'>
                    <img className='simple-element-img' src={emoji.static[6]}/>
                    <div className='info-texts-container'>
                        <span className='text-span x-text-span'>5 — <strong className='header-span-bold'>x2.50</strong></span>
                        <span className='text-span x-text-span'>4 — <strong className='header-span-bold'>x0.50</strong></span>
                        <span className='text-span x-text-span'>3 — <strong className='header-span-bold'>x0.25</strong></span>
                    </div>
                </div>
                <div className='simple-element-info'>
                    <img className='simple-element-img' src={emoji.static[7]}/>
                    <div className='info-texts-container'>
                        <span className='text-span x-text-span'>5 — <strong className='header-span-bold'>x2.50</strong></span>
                        <span className='text-span x-text-span'>4 — <strong className='header-span-bold'>x0.50</strong></span>
                        <span className='text-span x-text-span'>3 — <strong className='header-span-bold'>x0.25</strong></span>
                    </div>
                </div>
                <div className='simple-element-info'>
                    <img className='simple-element-img' src={emoji.static[8]}/>
                    <div className='info-texts-container'>
                        <span className='text-span x-text-span'>5 — <strong className='header-span-bold'>x1.25</strong></span>
                        <span className='text-span x-text-span'>4 — <strong className='header-span-bold'>x0.25</strong></span>
                        <span className='text-span x-text-span'>3 — <strong className='header-span-bold'>x0.10</strong></span>
                    </div>
                </div>
                <div className='simple-element-info'>
                    <img className='simple-element-img' src={emoji.static[9]}/>
                    <div className='info-texts-container'>
                        <span className='text-span x-text-span'>5 — <strong className='header-span-bold'>x1.25</strong></span>
                        <span className='text-span x-text-span'>4 — <strong className='header-span-bold'>x0.25</strong></span>
                        <span className='text-span x-text-span'>3 — <strong className='header-span-bold'>x0.10</strong></span>
                    </div>
                </div>
                <div className='simple-element-info'>
                    <img className='simple-element-img' src={emoji.static[10]}/>
                    <div className='info-texts-container'>
                        <span className='text-span x-text-span'>5 — <strong className='header-span-bold'>x1.25</strong></span>
                        <span className='text-span x-text-span'>4 — <strong className='header-span-bold'>x0.25</strong></span>
                        <span className='text-span x-text-span'>3 — <strong className='header-span-bold'>x0.10</strong></span>
                    </div>
                </div>
            </div>
            <div className='wild-container'> 
                <div className='wild-img-container'>
                    <img className='wild-element-img' src={emoji.static[11]}/>
                    <img className='wild-element-img' src={emoji.static[12]}/>
                </div>
                <div className='wild-description-container'>
                    <div className='br-margin'>
                        <span className='text-span wild-description'>Эти символы — <strong className='header-span-bold'>WILD</strong>, и они <strong className='header-span-bold'>могут заменять все символы на линии</strong>, кроме <img className='wild-description-img' src={emoji.static[13]}/></span>
                    </div>
                    <div className='br-margin'>
                        <span className='text-span wild-description'>Символы WILD присутствуют <strong className='header-span-bold'>только на барабанах 2, 3, 4.</strong> А также могут иметь множители <strong className='header-span-bold'>х2 или х3.</strong></span>
                    </div>
                    <div className='br-margin'>
                        <span className='text-span wild-description'><strong className='header-span-bold'>Множитель WILD применяется к выигрышу на линии</strong>, в которой символ WILD используется.</span>
                    </div>
                    <div className='br-margin'>
                        <span className='text-span wild-description'>Если на линии используется больше одного символа WILD, <strong className='header-span-bold'>множители всех символов WILD на данной линии суммируются.</strong></span>
                    </div>
                </div>
            </div>
            <div className='bonus-container'>
                <img className='bonus-element-img' src={emoji.static[13]}/>
                <div className='wild-description-container'>
                    <div className='br-margin'>
                        <span className='text-span wild-description'>Этот символ — <strong className='header-span-bold'>BONUS.</strong> Он появляется <strong className='header-span-bold'>только на барабанах 1, 3 и 5.</strong></span>
                    </div>
                    <div className='br-margin'>
                        <span className='text-span wild-description'>Выбейте <strong className='header-span-bold'>3 символа BONUS</strong>, чтобы <strong className='header-span-bold'>выиграть х5 от ставки</strong> и запустить <strong className='header-span-bold'>БОНУСКУ.</strong></span>
                    </div>
                </div>
            </div>
            <div className='free-spins-rules'>
                <div className='rules-header-div'>
                    <span className='text-span rules-header'>ПРАВИЛА БЕСПЛАТНЫХ СПИНОВ</span>
                </div>
                <div className='rules-body-div'>
                    <div className='br-margin'>
                        <span className='text-span wild-description'><strong className='header-span-bold'>Случайное количество бесплатных спинов</strong> присуждается до начала БОНУСКИ.</span>
                    </div>
                    <div className='br-margin'>
                        <span className='text-span wild-description'>Во время БЕСПЛАТНЫХ СПИНОВ все <strong className='header-span-bold'>символы WILD</strong>, выпавшие на барабанах 2, 3 и 4, <strong className='header-span-bold'>остаются на экране на своем месте протяжении всей БОНУСКИ.</strong></span>
                    </div>
                    <div className='br-margin'>
                        <span className='text-span wild-description'>Во время БОНУСКИ <strong className='header-span-bold'>символы BONUS на барабанах отсутствуют.</strong></span>
                    </div>
                </div>
            </div>
        
        </Div>
    </Panel>
)};

export default Rules;