import React from 'react';
import { Div, Group, Panel, PanelHeader, PanelHeaderBack, Placeholder } from '@vkontakte/vkui';
import { Icon28WarningTriangleOutline } from '@vkontakte/icons';
import './ErrorPanel.css'

const ErrorPage = props => (
    <Panel id={props.id}>
        <Group style={{padding: 0}}>
            <div style={{
                position: "absolute",
                top: 0
                }}>
                <div style={{
                    display: "table-cell",
                    verticalAlign: "middle",
                    height: document.documentElement.clientHeight
                    }}>
                    <Placeholder
                        icon={<Icon28WarningTriangleOutline width={56} height={56} className="bandit-icon" />}
                        header="произошла ошибка"
                        style={{verticalAlign: "middle"}}>
                        код ошибки: {props.code}. пожалуйста обратись в поддержку.
                    </Placeholder>
                </div>
            </div>
        </Group>
    </Panel>
);

export default ErrorPage;

