import React, { useState, useEffect, useRef} from 'react';
import axios from 'axios';
import PropTypes, { element } from 'prop-types';
import bridge from '@vkontakte/vk-bridge';
import { Panel, PanelHeader, Header, Button, Group, Cell, Div, Avatar, Input, ButtonGroup, Text, IconButton, FormItem, Spinner, Separator, Checkbox, platform, CellButton } from '@vkontakte/vkui';
import { Icon24Info } from '@vkontakte/icons';
import SlotMachine from '../mechanics/SlotMachine';
import './Home.css';
import defaultLines from '../mechanics/lines';
import textEmoji from '../mechanics/textEmoji';
import emoji from '../mechanics/emoji';

const Home = ({ id, fetchedUser, go, errorGo }) => {
	const [spin, setSpin] = useState(false);
	const [buttonState, setButtonState] = useState(false);
	const [autoButtonState, setAutoButtonState] = useState(false);
	const [winning, setWinning] = useState(0)
	const [showWinning, setShowWinning] = useState(false)
	const [field, setField] = useState(false)
	const [lines, setLines] = useState(false)
	const [textIndex, setTextIndex] = useState(0);
	const [texts, setTexts] = useState([]);
	const [opacityVector, setOpacityVector] = useState(0)
	const [elemsToShow, setElemsToShow] = useState([])
	const [inputStatus, setInputStatus] = useState('default')
	const [inputBet, setInputBet] = useState('$1.000')
	const [numBet, setNumBet] = useState(1000)
	const [balance, setBalance] = useState("")
	const [showSpinner, setShowSpinner] = useState(true)
	const [inputBottom, setInputBottom] = useState("")
	const [numBalance, setNumBalance] = useState(0)
	const [newBalance, setNewBalance] = useState(0)
	const [spinLoaderButton, setSpinLoaderButton] = useState(false)
	const [spinAutoLoaderButton, setSpinAutoLoaderButton] = useState(false)
	const [isInputAnimate, setIsInputAnimate] = useState(false)
	const [lineFiguresArr, setLineFiguresArr] = useState(false)
	const [lineFigures, setLineFigures] = useState(false)
	const [divLinesDisplay, setDivLinesDisplay] = useState('none')
	const [removedMarginBottom, setRemovedMarginBottom] = useState(false)
	const [isTurboSpin, setIsTurboSpin] = useState(false)
	const [isDisabledTurboSpin, setIsDisabledTurboSpin] = useState(false)
	const [isAutoSpin, setIsAutoSpin] = useState(false)
	const [isDisabledAutoSpin, setIsDisabledAutoSpin] = useState(false)
	const [isBonusGot, setIsBonusGot] = useState(false)
	const [freeSpins, setFreeSpins] = useState(0)
	const [bonusFields, setBonusFields] = useState(false)
	const [isBonusSpinsNow, setIsBonusSpinsNow] = useState(false)
	const [freeSpinIndex, setFreeSpinIndex] = useState(false)
	const [freeSpinIndexForMachine, setFreeSpinIndexForMachine] = useState(false)
	const [wildCoordinates, setWildCoordinates] = useState(false)
	const [allSpinsWinning, setAllSpinsWinning] = useState(0)
	const [winningBySpin, setWinningBySpin] = useState(0)
	const [isDisabledButtonGroup, setIsDisabledButtonGroup] = useState(false)
	const [isDisabledInput, setIsDisabledInput] = useState(false)
	const [isAnimateSpinPays, setIsAnimateSpinPays] = useState(false)
	const [isDisabledRules, setIsDisabledRules] = useState(false)
	const [allBonusWinning, setAllBonusWinning] = useState(false)
	const [isAutoSpinStarted, setIsAutoSpinStarted] = useState(false)
	const [timerId, setTimerId] = useState(false)
	const [isHeaderShows, setIsHeaderShows] = useState(false)
	const [currText, setCurrText] = useState("")
	const [textCounter, setTextCounter] = useState(false)
	const [opacityClass, setOpacityClass] = useState('')
	const [intervalId, setIntervalId] = useState(false)
	const [bonusEnd, setBonusEnd] = useState(false)

	const linesRef = useRef(null)
	const inputRef = useRef(null)
	const divLinesRef = useRef(null)
	const moneyRef = useRef(null)
	
	const tg = window.Telegram.WebApp

	const showRightWinning = (tempWinning = false) => {
		if (!showWinning) {
			return "УДАЧИ!";
		}
		else {
			if (winning == 0) {
				return `НЕ ПОВЕЗЛО`;
			} else {
				
				return `ВЫИГРЫШ ${pmoney((!tempWinning) ? winning : tempWinning)}`;
			}

			
		}
	}

	const increaseBet = (multiplier) => {
		if (isDisabledButtonGroup) {
			return
		}
		if (numBet == 0) {

			setInputStatus('error')
			setIsInputAnimate(true)
			if (tg.initData == '') {
				bridge.send('VKWebAppTapticNotificationOccurred', {
					type: 'error'
					})
					.then((data) => { 
					if (data.result) {
						console.log("OK")
					}
					})
					.catch((error) => {
					console.log(error);
				});
			} else {
				tg.HapticFeedback.notificationOccurred('error')
			}
			setTimeout(() => {
				setIsInputAnimate(false)
			}, 500)

			return;
		} 
		setInputStatus('default')
		setInputBottom('')
		if (Math.floor(Number(numBet * multiplier)) >= Number.MAX_SAFE_INTEGER) {
			return;
		}
		let newBet = Math.floor(numBet * multiplier)
		setNumBet(newBet)
		setInputBet(pmoney(newBet))
	}


	const handleDataFromChild = (dataFromChild) => {
		if (dataFromChild.disabled == true) {
			setButtonState(true);
			setSpin(false);
		}
		if (dataFromChild.disabled == false) {
			var figuresArr = [];
			var textArr = [];
			if (lines === null || lines.length == 0) {
				setTexts([]);
				setRemovedMarginBottom(false)
			} else {
				if (isAutoSpin && isTurboSpin && winning < numBet * 5) {
					setRemovedMarginBottom(false)
				} else {
					setRemovedMarginBottom(true)
				}
				if (!isBonusGot) {
					for (let i = 0; i < lines.length; i++) {
					
						if (lines[i].length == 4) {
							let figures = []
							for (let j = 0; j < lines[i][3].length; j++) {
								figures.push(emoji.static[lines[i][3][j]])
							}
							figuresArr.push(figures)
							textArr.push([`Линия ${lines[i][0]} платит ${pmoney(lines[i][1])}`, lines[i][0], lines[i][2]]);
						} else {
							let figures = []
							for (let j = 0; j < lines[i][5].length; j++) {
								figures.push(emoji.static[lines[i][5][j]])
							}
							figuresArr.push(figures)
							textArr.push([`Линия ${lines[i][0]} платит ${pmoney(lines[i][1])} x ${lines[i][2]} = ${pmoney(lines[i][3])}`, lines[i][0], lines[i][4]])
						}
						
					}
					if (isBonusSpinsNow) {
						textArr.push([`Спин платит ${pmoney(winningBySpin)}`])
					}
				}
				else {
					textArr.push([`Количество бесплатных вращений — ${freeSpins}`])
					figuresArr = false
				}
				if (figuresArr !== false && figuresArr.length == 0) {
					figuresArr = false
				}
				setLineFiguresArr(figuresArr)
				setTexts(textArr);
				
				if (textArr.length !== 0) {
					if (isBonusSpinsNow) {
						var bonusWinning = winning
						const moneyPerInterval = Math.floor(Number(bonusFields[freeSpinIndex].all_sum / (textArr.length * 150)))
						const intervalID = setInterval(() => {
							bonusWinning += moneyPerInterval
							moneyRef.current.innerText = `ВЫИГРЫШ ${pmoney(bonusWinning)}`
						}, 20)
						setIntervalId(intervalID)
						setTextCounter(0)
					}
					else if (!isAutoSpin || (isAutoSpin && !isTurboSpin) || (isAutoSpin && isTurboSpin && winning >= numBet * 5)) {
						setTextCounter(0)
						if (!isBonusGot) {
							setBalance(pmoney(newBalance))
						}
						
					}
				}
				setDivLinesDisplay('flex')
			}
			
			setShowWinning(true)
			
			setSpin(false);
			if (isBonusGot) {
				setButtonState(true);
				setAutoButtonState(true);
				setIsAutoSpin(false)
			} else if (isBonusSpinsNow) {
				setIsDisabledTurboSpin(true)
				setIsDisabledAutoSpin(true)
				setIsTurboSpin(false)
				if (!isAutoSpin) {
					setIsAutoSpin(false)
					setIsDisabledAutoSpin(true)
				}
				setIsDisabledButtonGroup(true)
				setIsDisabledInput(true)
				setIsDisabledRules(true)
				setButtonState(true);
				setAutoButtonState(true);
				if (textArr.length === 0) {
					setTimeout(() => {
						setWinning(winning + bonusFields[freeSpinIndex].all_sum)
						setFreeSpinIndex(freeSpinIndex + 1)
					}, 1000);
				}
			} else if (isAutoSpin) {
				setIsDisabledButtonGroup(true)
				setIsDisabledInput(true)
				setIsDisabledRules(true)
				setButtonState(true);
				setBalance(pmoney(newBalance))
				if (textArr.length === 0 || (isTurboSpin && winning < numBet * 5)) {
					setTimeout(() => {
						handleSpin("auto")
					}, (isTurboSpin) ? 200 : 1000);
				}
			} else {
				setBalance(pmoney(newBalance))
				setNumBalance(Number(newBalance))
				setButtonState(false);
				setIsDisabledTurboSpin(false)
				setIsDisabledAutoSpin(false)
				setIsDisabledInput(false)
				setIsDisabledRules(false)
				setIsDisabledButtonGroup(false)
				setIsDisabledAutoSpin(false)
			}
		}
		
	}

	
	const handleCheckBox = (e) => {
		if (!isDisabledTurboSpin) {
			setIsTurboSpin(!isTurboSpin)
		}
		
	}

	const handleAutoCheckBox = (e) => {
		if (isAutoSpin) {
			if (!spin) {
				setIsAutoSpin(false)
				setButtonState(true)
				setIsDisabledAutoSpin(false)
				setIsDisabledButtonGroup(false)
				setIsDisabledInput(false)
				setIsDisabledRules(false)
				setIsDisabledTurboSpin(false)
				setButtonState(false)	
			} else {
				setIsAutoSpin(false)
				setButtonState(true)
				setIsDisabledAutoSpin(true)
			}	
		}
		setIsAutoSpin(!isAutoSpin)
		if (isAutoSpinStarted) {
			setIsAutoSpinStarted(!isAutoSpinStarted)
		}
		
	}
	const stopAuto = () => {
		if (tg.initData == '') {
			bridge.send('VKWebAppTapticImpactOccurred', {
				style: 'heavy'
				})
				.then((data) => { 
				if (data.result) {
					console.log("OK")
				}
				})
				.catch((error) => {
				console.log(error);
			});
		} else {
			tg.HapticFeedback.notificationOccurred('success')
		}
		if (isAutoSpinStarted) {
			if (!spin) {
				setIsAutoSpin(false)
				setIsDisabledAutoSpin(false)
				setIsDisabledButtonGroup(false)
				setIsDisabledInput(false)
				setIsDisabledRules(false)
				setIsDisabledTurboSpin(false)
				setButtonState(false)	
			} else {
				setIsAutoSpin(false)
				setButtonState(true)
				setIsDisabledAutoSpin(true)
			}	
			
		} else {
			setIsAutoSpin(true)
			setIsAutoSpinStarted(true)
			handleSpin("auto")
			
			
		}
		
	}
	const handleSpin = (mode = false) => {
		
		if (buttonState === false || isAutoSpin === true) {
			if (numBet < 100) {
				
				setInputStatus('error')
				setIsInputAnimate(true)
				if (tg.initData == '') {
					bridge.send('VKWebAppTapticNotificationOccurred', {
						type: 'error'
						})
						.then((data) => { 
						if (data.result) {
							console.log("OK")
						}
						})
						.catch((error) => {
						console.log(error);
					});
				} else {
					tg.HapticFeedback.notificationOccurred('error')
				}
				
				setTimeout(() => {
					setIsInputAnimate(false)
				}, 500)
				return;
			}
			if (numBet == 0) {
				
				setInputStatus('error')
				setIsInputAnimate(true)
				if (tg.initData == '') {
					bridge.send('VKWebAppTapticNotificationOccurred', {
						type: 'error'
						})
						.then((data) => { 
						if (data.result) {
							console.log("OK")
						}
						})
						.catch((error) => {
						console.log(error);
					});
				} else {
					tg.HapticFeedback.notificationOccurred('error')
				}
				setTimeout(() => {
					setIsInputAnimate(false)
				}, 500)
				return;
			}
			// if (numBet > numBalance) {
				
			// 	setInputStatus('error')
			// 	setIsInputAnimate(true)
			// 	if (tg.initData == '') {
			// 		bridge.send('VKWebAppTapticNotificationOccurred', {
			// 			type: 'error'
			// 			})
			// 			.then((data) => { 
			// 			if (data.result) {
			// 				console.log("OK")
			// 			}
			// 			})
			// 			.catch((error) => {
			// 			console.log(error);
			// 		});
			// 	} else {
			// 		tg.HapticFeedback.notificationOccurred('error')
			// 	}
			// 	setTimeout(() => {
			// 		setIsInputAnimate(false)
			// 	}, 500)
			// 	return;
			// }
			if (!isAutoSpin) {
				setButtonState(true);
				setSpinLoaderButton(true)
			} else {
				setAutoButtonState(true);
				setSpinAutoLoaderButton(true);
				setIsDisabledAutoSpin(true);
			}
			
			setShowWinning(false)
			const params = {
				type: 'field',
				bet: numBet,
				id: fetchedUser.id,
				platform: fetchedUser.platform,
				url: window.location.href,
				rawData: fetchedUser.initData
			};
			//https://botbandit.com/slots/api.php
			sendRequest(params).then((response) => { 
				if (!isAutoSpin) {
					if (tg.initData == '') {
						bridge.send('VKWebAppTapticImpactOccurred', {
							style: 'heavy'
							})
							.then((data) => { 
							if (data.result) {
								console.log("OK")
							}
							})
							.catch((error) => {
							console.log(error);
						});
					} else {
						tg.HapticFeedback.notificationOccurred('success')
					}
				}
				if (!response.data.ok)
				{
					if (response.data.errno != 6) {
						errorGo('errorPage', response.data.errno);
					} else {
						setBalance(pmoney(response.data.balance))
						setNumBalance(Number(response.data.balance))
						setInputStatus('error')
						setSpinLoaderButton(false)
						setButtonState(false)
						setIsInputAnimate(true)
						setIsAutoSpin(false)
						setIsDisabledAutoSpin(false)
						setIsDisabledButtonGroup(false)
						setIsDisabledInput(false)
						setIsDisabledRules(false)
						setIsDisabledTurboSpin(false)
						setSpinAutoLoaderButton(false);
						setAutoButtonState(false)
						if (tg.initData == '') {
							bridge.send('VKWebAppTapticNotificationOccurred', {
								type: 'error'
								})
								.then((data) => { 
								if (data.result) {
									console.log("OK")
								}
								})
								.catch((error) => {
								console.log(error);
							});
						} else {
							tg.HapticFeedback.notificationOccurred('error')
						}
						setTimeout(() => {
							setIsInputAnimate(false)
						}, 500)
						return false
					}
					return false
				}
				setInputStatus('default')
				setField(response.data.field.field)
				setWinning(response.data.field.all_sum)
				setIsAnimateSpinPays(false)
				if (response.data.field.bonus) {
					setAllBonusWinning(response.data.field.all_sum)
					setIsBonusGot(true)
					setWinning(response.data.field.betx5)
					setFreeSpins(response.data.field.spins)
					setBonusFields(response.data.field.bonus_fields)
					setWildCoordinates(response.data.field.wild_coordinates)
				}
				setLines(response.data.field.winning_lines)
				
				setShowWinning(false)

				setTexts([])
				setElemsToShow([])
				setTextCounter(false)
				setOpacityClass('')
				setBonusEnd(false)
				setSpin(true)
				
				setSpinLoaderButton(false)
				setSpinAutoLoaderButton(false)
				setAutoButtonState(false)
				setBalance(pmoney(response.data.balance_without_win))
				setNumBalance(Number(response.data.balance_without_win))
				setNewBalance(response.data.balance)
				setLineFiguresArr(false)
				setLineFigures(false)
				setDivLinesDisplay('none')
				setRemovedMarginBottom(false)
				setIsDisabledTurboSpin(true)
				if (!isAutoSpin) {
					setIsDisabledAutoSpin(true)
				} else {
					setIsDisabledAutoSpin(false)
				}
				setIsDisabledButtonGroup(true)
				setIsDisabledInput(true)
				setIsDisabledRules(true)
				linesRef.current.innerText = ""	
			})
			.catch((error) => {
				errorGo('errorPage', 10);
			});
		}
	};

	const getElementsToShow = (line, num) => {
		const tempArr = defaultLines[line - 1]
		var resultArr = []
		for (let i = 0; i < num; i++) {
			resultArr.push(tempArr[i])
		}
		return resultArr
	}

	const showAutoSpinButtonText = () => {
		if (isAutoSpinStarted) {
			return "ПРЕКРАТИТЬ АВТО-СПИН"
		} else {
			return "НАЧАТЬ АВТО-СПИН"
		}
	}

	const pmoney = (money, noDollar = false) => {
		if (money >= 0) {
			let strMoney = String(money);
			let result = "";
	
			while (strMoney.length > 3)
			{
				result = "." + strMoney.slice(-3) + result;
				strMoney = strMoney.slice(0, -3);
			}
	
			if (!noDollar) {
				result = "$" + strMoney + result;
			} else {
				result = strMoney + result;
			}
	
			return result;
		} else {
			return "ERROR";
		}
	}

	const onChange = (e) => {
		if (isDisabledInput) {
			return
		}
		function umoney(str) {
			return str.replace(/[^+\d]/g, '');
		}
		const { value } = e.currentTarget;
		var newValue
		if (value.length < 2) {
			newValue = umoney("0")
		} else {
			if (value[1] == '0') {
				newValue = umoney(value).slice(1)
			} else {
				newValue = umoney(value)	
			}
			if (Number(newValue) >= Number.MAX_SAFE_INTEGER) {
				return;
			}
			
		}
		setInputStatus('default')
		setInputBottom('')
		setInputBet(pmoney(newValue))
		setNumBet(Number(newValue))
	}

	const getBalanceForTop = () => {
		if (showSpinner) {
			return "Ставка"
		} else {
			return `Ставка (баланс — ${balance})`
		}
	}

	async function sendRequest(params) {
		var isSuccess = false;
		while (!isSuccess) {
			try {
				const response = await axios.get('https://botbandit.com/slots/api.php', { params })
				if ("ok" in response.data) {
					isSuccess = true;
					return response;
				} else {
					isSuccess = false;
				}
			} catch {
				isSuccess = false
			}
		}
	}

	useEffect(() => {
		if (fetchedUser !== null) {
			const params = {
				type: 'get_balance',
				id: fetchedUser.id,
				platform: fetchedUser.platform,
				url: window.location.href,
				rawData: fetchedUser.initData
			};
			if (fetchedUser.platform == 'vk') 
			{
				setIsHeaderShows(true)
			}
			
			sendRequest(params).then((response) =>{
				if (!response.data.ok)
				{
					if (response.data.errno != 6) {
						errorGo('errorPage', response.data.errno);
					} else {
						setBalance(pmoney(response.data.balance))
						setNumBalance(Number(response.data.balance))
						setInputStatus('error')
						
						setIsInputAnimate(true)
						if (tg.initData == '') {
							bridge.send('VKWebAppTapticNotificationOccurred', {
								type: 'error'
								})
								.then((data) => { 
								if (data.result) {
									console.log("OK")
								}
								})
								.catch((error) => {
								console.log(error);
							});
						} else {
							tg.HapticFeedback.notificationOccurred('error')
						}
						setTimeout(() => {
							setIsInputAnimate(false)
						}, 500)
						return false
					}
					return false
				}
				setShowSpinner(false)
				setBalance(pmoney(response.data.balance))
				setNumBalance(Number(response.data.balance))
			})
			.catch((error) => {
				errorGo('errorPage', 10);
			})	
		}
	}, [fetchedUser])

	
	const opacityAnimationHandler = () => {
		if (textCounter !== false) { 
	 
			if (textCounter + 1 <= texts.length - 1) {
				setTextCounter(textCounter + 1)
			} else {
				if (isAutoSpin && isAutoSpinStarted) {
					handleSpin("auto")
				} else if (isBonusGot) {
					setIsBonusSpinsNow(true)
					setIsBonusGot(false)
					setFreeSpinIndex(0)
					setTextCounter(false)
					setElemsToShow([])
				} else if (isBonusSpinsNow) {
					clearInterval(intervalId)
					setWinning(winning + bonusFields[freeSpinIndex].all_sum)
					setFreeSpinIndex(freeSpinIndex + 1)
				} else {
					setTextCounter(0)
				}
			}			
		}
		
	}

	useEffect(() => {
		if (texts.length == 0 || texts == null || texts == undefined || !texts || linesRef == null || linesRef == undefined) {
			return
		}
		if (texts.length == 0) {
			linesRef.current.innerText = ""
			return
		}
		if (textCounter === false) {
			return
		}
		linesRef.current.innerText = texts[textCounter][0];
		setLineFigures(lineFiguresArr[textCounter])
		if (!isBonusGot) {
			setElemsToShow(getElementsToShow(texts[textCounter][1], texts[textCounter][2]))
			setOpacityClass('opacity-changer')
		} else {
			setElemsToShow(lines)
			setOpacityClass('opacity-changer-bonus-got')
		}
		
	}, [textCounter]);

	useEffect(() => {
		if (!isBonusSpinsNow || freeSpinIndex >= bonusFields.length) {
			setWinning(allBonusWinning)
			setIsBonusSpinsNow(false)
			setButtonState(false)
			setAutoButtonState(false)
			setWildCoordinates(false)
			setFreeSpinIndex(false)
			if (!isAutoSpin) {
				setIsDisabledTurboSpin(false)
				setIsDisabledAutoSpin(false)
			}
	
			setBalance(pmoney(newBalance))
			setNumBalance(Number(newBalance))
			if (!isAutoSpin) {
				setIsDisabledButtonGroup(false)
				setIsDisabledInput(false)
				setIsDisabledRules(false)
				setIsDisabledAutoSpin(false)
			}
			if (freeSpinIndex !== false) {
				setTexts([texts[texts.length-1]]);
			}
			setLines(false)
			setLineFiguresArr(false)
			setLineFigures(false)
			moneyRef.current.innerText = showRightWinning(allBonusWinning)
			setIsAnimateSpinPays(true)
			setElemsToShow([]);
			setBonusEnd(true)
			return
		}
		setIsTurboSpin(false)
		if (!isAutoSpin) {
			setIsAutoSpin(false)
		}
		setAutoButtonState(true)
		setIsDisabledTurboSpin(true)
		setIsDisabledAutoSpin(true)
		setIsDisabledButtonGroup(true)
		setIsDisabledInput(true)
		setIsDisabledRules(true)
		
		setField(bonusFields[freeSpinIndex].field)
		
		setLines(bonusFields[freeSpinIndex].winning_lines)
		setShowWinning(true)
		setWinningBySpin(bonusFields[freeSpinIndex].all_sum)
		setTexts([]);
		setSpinLoaderButton(false)
		
		setLineFiguresArr(false)
		setLineFigures(false)
		setDivLinesDisplay('none')
		setRemovedMarginBottom(false)
		linesRef.current.innerText = ""
		moneyRef.current.innerText = showRightWinning()
		setSpin(true)	
	}, [freeSpinIndex])
	
	
	return (
		<Panel id={id} >
			{isHeaderShows && <PanelHeader>THE BANDIT HOUSE</PanelHeader>}
			<Group separator='hide' mode='plain' style={{padding: 0}}>
				<SlotMachine spin={spin} field={field} animateElems={elemsToShow} turboSpin={isTurboSpin} bonus={isBonusGot} bonusSpins={isBonusSpinsNow} spinIndex={freeSpinIndex} onData={handleDataFromChild} bonusEnd={bonusEnd}/>
			</Group>
			<Separator />
			<Group separator='hide' mode='plain'>
				<Text getRootRef={moneyRef} className={`spin-info spin-info-down ${removedMarginBottom ? 'removed-margin-bottom' : ''}`}>{showRightWinning()}</Text>
				<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
					<div ref={divLinesRef} className={opacityClass} onAnimationIteration={opacityAnimationHandler} style={{display: divLinesDisplay, alignItems: 'center', justifyContent: 'center'}}>
						{lineFigures && 
							<div style={{ display: 'flex' }}>
								{lineFigures.map((path, index) => (
									<img
									key={index}
									src={path}
									style={{ height: '25px', width: '25px', marginRight: '1px' }} 
									/>
								))}
								<span style={{display: 'none', marginRight: '6px'}}>—</span>
							</div>
						}
						
						<Text getRootRef={linesRef} className={`spin-info spin-info-up`}></Text>
					</div>
					{/* {lineFigures && 
						<Text className='spin-info spin-info-up'></Text>
					} */}
					{isBonusSpinsNow && 
						<Text style={{marginTop: '0'}} className={`spin-info ${removedMarginBottom ? 'removed-margin-bottom' : ''}`}>Осталось бесплатных вращений - {freeSpins - (freeSpinIndex + 1)}/{freeSpins}</Text>
					}
				</div>
				
					
			</Group>
			<Separator />
			<Group separator='hide' mode='plain'>
				
				<FormItem style={{paddingBottom: '0px', paddingTop: '0px'}}
					status={inputStatus} 
					top={getBalanceForTop()}
					// className='bet-input'
				>
					<Input
						className={`input ${isInputAnimate ? 'input-animate' : ''}`}
						disabled={isDisabledInput}
						getRootRef={inputRef}
						value={inputBet}
						align='center'
						onChange={onChange}
						id = 'bet'
						type = 'text'
						placeholder='Ваша ставка'
					/>
				</FormItem>
				<FormItem style={{paddingBottom: '0px'}}>
					<ButtonGroup disabled={isDisabledButtonGroup} className='bet-increase-group'>
						<Button disabled={isDisabledButtonGroup} stretched={true} size="l" appearance="neutral" onClick={() => increaseBet(0.1)}>
						0.1x
						</Button>
						{/* <div style={{ flex: 1 }}></div> */}
						<Button disabled={isDisabledButtonGroup} stretched={true} size="l" appearance="neutral" onClick={() => increaseBet(0.5)}>
						0.5x
						</Button>
						{/* <div style={{ flex: 1 }}></div> */}
						<Button disabled={isDisabledButtonGroup} stretched={true} size="l" appearance="neutral" onClick={() => increaseBet(2)}>
						2x
						</Button>
						{/* <div style={{ flex: 1 }}></div> */}
						<Button disabled={isDisabledButtonGroup} stretched={true} size="l" appearance="neutral" onClick={() => increaseBet(10)}>
						10x
						</Button>
					</ButtonGroup>
				</FormItem>
				
				<FormItem>
					{!isAutoSpin &&
						<Button className='do-spin-button' mode="primary" loading={spinLoaderButton} onClick={handleSpin} disabled={buttonState}>
							<span className='do-spin-button-text'>КРУТИТЬ</span>
						</Button>
					}
					{isAutoSpin && 
						<Button appearance='negative' className='do-spin-button stop-auto' mode="primary" loading={spinAutoLoaderButton} onClick={stopAuto} disabled={autoButtonState}>
							<span className='do-spin-button-text'>{showAutoSpinButtonText()}</span>
						</Button>
					}
				</FormItem>
				<Separator />
				<Checkbox checked={isTurboSpin} disabled={isDisabledTurboSpin} onChange={handleCheckBox}>
					Турбо-спин
				</Checkbox>
				<Checkbox checked={isAutoSpin} disabled={isDisabledAutoSpin} onChange={handleAutoCheckBox}>
					Авто-спин
				</Checkbox>
				<CellButton disabled={isDisabledRules} before={<Icon24Info />} onClick={go} data-to="rules">Правила игры</CellButton>
			</Group>
		</Panel>
	);
};

Home.propTypes = {
	id: PropTypes.string.isRequired,
	go: PropTypes.func.isRequired,
	fetchedUser: PropTypes.shape({
		photo_200: PropTypes.string,
		first_name: PropTypes.string,
		last_name: PropTypes.string,
		city: PropTypes.shape({
			title: PropTypes.string,
		}),
	}),
};

export default Home;
