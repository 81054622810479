import devil from "../img/emoji_json/devil.json"
import bonus from "../img/emoji_json/bonus.json"
import burger from "../img/emoji_json/burger.json"
import butterfly from "../img/emoji_json/butterfly.json"
import strawberry from "../img/emoji_json/strawberry.json"
import pig from "../img/emoji_json/pig.json"
import tiger from "../img/emoji_json/tiger-face.json"
import frozen from "../img/emoji_json/frozen.json"
import gorilla from "../img/emoji_json/gorilla.json"
import candy from "../img/emoji_json/candy.json"
import purple_devil from "../img/emoji_json/purple_devil.json"
import robot from "../img/emoji_json/robot.json"
import x2 from "../img/emoji_json/x2.json"
import x3 from "../img/emoji_json/x3.json"

import devil_img from "../img/static_emoji/devil.png"
import bonus_img from "../img/static_emoji/bonus.png"
import burger_img from "../img/static_emoji/burger.png"
import butterfly_img from "../img/static_emoji/butterfly.png"
import strawberry_img from "../img/static_emoji/strawberry.png"
import pig_img from "../img/static_emoji/pig.png"
import tiger_img from "../img/static_emoji/tiger-face.png"
import frozen_img from "../img/static_emoji/frozen.png"
import gorilla_img from "../img/static_emoji/gorilla.png"
import candy_img from "../img/static_emoji/candy.png"
import purple_devil_img from "../img/static_emoji/purple_devil.png"
import robot_img from "../img/static_emoji/robot.png"
import x2_img from "../img/static_emoji/x2.png"
import x3_img from "../img/static_emoji/x3.png"
import bigline1 from "../img/biglines/bigline1.jpg"
import bigline2 from "../img/biglines/bigline2.jpg"
import bigline3 from "../img/biglines/bigline3.jpg"
import bigline4 from "../img/biglines/bigline4.jpg"
import bigline5 from "../img/biglines/bigline5.jpg"
import bigline1_dark from "../img/biglines/bigline1_dark.jpg"
import bigline2_dark from "../img/biglines/bigline2_dark.jpg"
import bigline3_dark from "../img/biglines/bigline3_dark.jpg"
import bigline4_dark from "../img/biglines/bigline4_dark.jpg"
import bigline5_dark from "../img/biglines/bigline5_dark.jpg"

const emoji = {animations: [devil, purple_devil, frozen, robot, pig, butterfly, gorilla, tiger, burger, strawberry, candy, x2, x3, bonus],
               static:  [devil_img, purple_devil_img, frozen_img, robot_img, pig_img, butterfly_img, gorilla_img, tiger_img, burger_img, strawberry_img, candy_img, x2_img, x3_img, bonus_img],
               bigline: { light: [bigline1, bigline2, bigline3, bigline4, bigline5],
                          dark: [bigline1_dark, bigline2_dark, bigline3_dark, bigline4_dark, bigline5_dark] }
               }

export default emoji
