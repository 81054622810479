import React, {Component} from 'react';
import { ReactDOM } from 'react';
import SlotMachineElement from './SlotMachineElement';
import SlotMachineReel from './SlotMachineReel';
import './SlotMachine.css';
import { Button } from '@vkontakte/vkui';

class SlotMachine extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			counter: 0,
			startSpin: false,
			resetState: false,
			emoji: this.getEmojiForDocument(), //[this.getEmojiForDocument(), this.getEmojiForDocument(), this.getEmojiForDocument(), this.getEmojiForDocument(), this.getEmojiForDocument()],
			field: false
		}
		this.reelRef = React.createRef();
		
	}

	componentDidMount() {
		
		this.parentBounds = this.getReelBounds();
		
	}

	componentDidUpdate(prevProps, prevState) {

		if ((prevProps.spin != this.props.spin) || (this.state.counter == 0)) {
			if (this.props.spin == true && this.state.counter > 1 && !this.state.startSpin) {
				// this.sendDataToParent({disabled: true});
				this.setState({startSpin: true, counter: this.state.counter + 1, resetState: true, emoji: this.processEmoji(), field: this.parsField()})
			}
			else if (this.props.spin == true && this.state.counter == 1 && !this.state.startSpin) {
				// this.sendDataToParent({disabled: true});
				this.setState({startSpin: true, counter: this.state.counter + 1, resetState: true, emoji: this.processEmoji(true), field: this.parsField()})
			}
			else if (this.props.spin == false && this.state.counter == 0) {
				
				this.setState({counter: this.state.counter + 1})
			}
		}
	}

	handleDataFromChild = (dataFromChild) => {
		if (dataFromChild.disabled == false) {
			this.sendDataToParent({disabled: false})
			this.setState({startSpin: false, resetState: false})
		}
	}

	sendDataToParent = (data) => {
        this.props.onData(data);
    }

	parsField = () => {
		if (this.props.field !== false) {
			const matrix = this.props.field
			var transpotedMatrix = []
			for (let i = 0; i < 5; i++) {
				transpotedMatrix.push([])
				for (let j = 0; j < 3; j++) {
					transpotedMatrix[i].push(matrix[j][i])
				}
			}
			return transpotedMatrix
		}
	}

	emoji = ['👹', '😈', '🥶', '🤖', '🐶', '🐝', '🍕', '🍟', '🍔', '🍫', '🍿', '2️⃣','3️⃣', '💎']

	getRandomEmoji = () => {
		const randomIndex = Math.floor(Math.random() * 14);
		return randomIndex
	};

	getEmojiForDocument = () => {
		
		var emojiArr = []
		for (let j = 0; j < 5; j++) {
			var emoji_for_document = []
			for (let i = 0; i <= 3; i++)
			{
				if (j != 1 && j != 2 && j != 3)
				{
					var emoji = this.getRandomEmoji();
					while (emoji == 11 || emoji == 12) {
						emoji = this.getRandomEmoji();
					}
					emoji_for_document.push(emoji)
				}
				else if (j != 0 && j != 2 && j != 4)
				{
					var emoji = this.getRandomEmoji();
					while (emoji == 13) {
						emoji = this.getRandomEmoji();
					}
					emoji_for_document.push(emoji)
				}
				else
				{
					emoji_for_document.push(this.getRandomEmoji())
				}
				
			}
			emojiArr.push(emoji_for_document)
		}
		return emojiArr;
	}

	getReelBounds() {
		const reelElement = this.reelRef.current;
		if (!reelElement) return;

		const reelRect = reelElement.getBoundingClientRect();
	
		return [reelRect.top, reelRect.bottom];
	}

	processEmoji = (firstSpin = false) => {
		if (this.props.field !== false) {
			const matrix = this.props.field
			var transpotedMatrix = []
			for (let i = 0; i < 5; i++) {
				transpotedMatrix.push([])
				for (let j = 0; j < 3; j++) {
					transpotedMatrix[i].push(matrix[j][i])
				}
			}
		}

		let emojiArr = this.state.emoji

		for (let i = 0; i < emojiArr.length; i++){
			
			emojiArr[i][emojiArr[i].length - 4] = emojiArr[i][0] 
			emojiArr[i][emojiArr[i].length - 3] = emojiArr[i][1] 
			emojiArr[i][emojiArr[i].length - 2] = emojiArr[i][2] 	
			
			// for (let j = 3; j < 100; j++) {
			// 	emojiArr[i][j] = this.getRandomEmoji()
			// }
			if (this.props.field !== false) {
				
				emojiArr[i][0] = transpotedMatrix[i][0] - 1
				emojiArr[i][1] = transpotedMatrix[i][1] - 1
				emojiArr[i][2] = transpotedMatrix[i][2] - 1
			}
			else {
				emojiArr[i][0] = this.getRandomEmoji()
				emojiArr[i][1] = this.getRandomEmoji()
				emojiArr[i][2] = this.getRandomEmoji()
			}
			emojiArr[i][3] = 0
		}

		
		console.log(`ZHOPA ${emojiArr}`)
		return emojiArr;
	}

	render() {
		
		return (
			<div className="slotPanel" ref={this.reelRef}>
				<div className="slot-machine">
					
					<SlotMachineReel animateElems={this.props.animateElems} resetState={this.state.resetState} turboSpin={this.props.turboSpin} spin={this.state.startSpin} bonusSpins={this.props.bonusSpins} spinIndex={this.props.spinIndex} emoji={this.state.emoji[0]} field={this.state.field} bounds={this.parentBounds} onData={this.handleDataFromChild} index={0} bonusEnd={this.props.bonusEnd} />
					<SlotMachineReel animateElems={this.props.animateElems} resetState={this.state.resetState} turboSpin={this.props.turboSpin} spin={this.state.startSpin} bonusSpins={this.props.bonusSpins} spinIndex={this.props.spinIndex} emoji={this.state.emoji[1]} field={this.state.field} bounds={this.parentBounds} onData={this.handleDataFromChild} index={1} bonusEnd={this.props.bonusEnd}/> 
					<SlotMachineReel animateElems={this.props.animateElems} resetState={this.state.resetState} turboSpin={this.props.turboSpin} spin={this.state.startSpin} bonusSpins={this.props.bonusSpins} spinIndex={this.props.spinIndex} emoji={this.state.emoji[2]} field={this.state.field} bounds={this.parentBounds} onData={this.handleDataFromChild} index={2} bonusEnd={this.props.bonusEnd}/> 
					<SlotMachineReel animateElems={this.props.animateElems} resetState={this.state.resetState} turboSpin={this.props.turboSpin} spin={this.state.startSpin} bonusSpins={this.props.bonusSpins} spinIndex={this.props.spinIndex} emoji={this.state.emoji[3]} field={this.state.field} bounds={this.parentBounds} onData={this.handleDataFromChild} index={3} bonusEnd={this.props.bonusEnd}/> 
					<SlotMachineReel animateElems={this.props.animateElems} resetState={this.state.resetState} turboSpin={this.props.turboSpin} spin={this.state.startSpin} bonusSpins={this.props.bonusSpins} spinIndex={this.props.spinIndex} emoji={this.state.emoji[4]} field={this.state.field} bounds={this.parentBounds} onData={this.handleDataFromChild} index={4} bonusEnd={this.props.bonusEnd}/>
				</div>
			</div>
		);
	}
}

export default SlotMachine


