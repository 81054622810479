import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import { View, ScreenSpinner, AdaptivityProvider, AppRoot, ConfigProvider, SplitLayout, SplitCol, Alert } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import axios from 'axios';

import Home from './panels/Home';
import Rules from './panels/Rules';
import ErrorPage from './panels/ErrorPanel';
import emoji from "./mechanics/emoji"

const App = () => {
	const tg = window.Telegram.WebApp;
	const [activePanel, setActivePanel] = useState('home');
	const [fetchedUser, setUser] = useState(null);
	const [popout, setPopout] = useState(<ScreenSpinner size='large' />);
	const [errorCode, setErrorCode] = useState(5);

	useEffect(() => {
		async function fetchData() {
			var user
			if (tg.initData == '') {

				user = await bridge.send('VKWebAppGetUserInfo');
				user.platform = 'vk'
				user.initData = ''
				setUser(user);
				setPopout(null);
			} else {
				user = {platform: 'tg', initData: tg.initData}
				tg.expand()
				setUser(user)
				setPopout(null);
			}
		}
		fetchData();
	}, []);

	const go = e => {
		setActivePanel(e.currentTarget.dataset.to);
		return false;
	};

	const errorGo = (newActivePanel, newErrorCode) => {
		setActivePanel(newActivePanel);
		setErrorCode(newErrorCode);
	}

	return (
		<ConfigProvider>
			<AdaptivityProvider>
				<AppRoot>
					<SplitLayout popout={popout}>
						<SplitCol>
							<View style={{backgroundColor: "rgb(255,255,255)"}} activePanel={activePanel}>
								<Home id='home' fetchedUser={fetchedUser} go={go} errorGo={errorGo}/>
								<Rules id='rules' go={go} />
								<ErrorPage id='errorPage' code={errorCode} />
							</View>
						</SplitCol>
					</SplitLayout>
				</AppRoot>
			</AdaptivityProvider>
		</ConfigProvider>
	);
}

export default App;
