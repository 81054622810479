import React, {Component} from 'react';
import './SlotMachine.css';
import SlotMachineElement from './SlotMachineElement';

class SlotMachineReel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emoji: this.props.emoji,
            blockedWilds: []
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // if (prevProps.bonusSpins !== false && prevProps.field !== false ) {
        //     let wilds = []
        //     for (let j = 0; j < 3; j++) {
        //         if (prevProps.field[this.props.index][j] == 12 || prevProps.field[this.props.index][j] == 13) {
        //             wilds.push(j)
        //         }
        //     }
        //     this.setState({blockedWilds: wilds})
        // }
    }

    getReelBounds() {
		const reelElement = this.reelRef.current;
		if (!reelElement) return;

		const reelRect = reelElement.getBoundingClientRect();
	
		return [reelRect.top, reelRect.bottom];
	}

    sendDataToParent = (data) => {
        this.props.onData(data);
    }

    handleDataFromChild = (dataFromChild) => {
        if (dataFromChild.disabled === false) {
            this.sendDataToParent({disabled: false})
        }
	}

    render() {
        const slotMachineElements = this.props.emoji.map((emoji, index) => {
            
            var z_index = 0
            if (this.props.bonusSpins && index >= 0 && index <= 2 && emoji >= 11 && emoji <= 12) {
                z_index = 1
            }

            return (
            
            <SlotMachineElement
              key={index}
              elemIndex = {index}
              zIndex = {z_index}
              animateElems={this.props.animateElems} 
              resetState={this.props.resetState}
              turboSpin={this.props.turboSpin} 
              bonusSpins={this.props.bonusSpins} 
              spinIndex={this.props.spinIndex}
              spin={this.props.spin}
            //   blocked={(this.state.blockedWilds.includes(index)) ? true : false}
              emoji={emoji}
              onData={this.handleDataFromChild}
              index={this.props.index}
              last={index === 0}
              bigline={index === 3}
              bonusEnd={this.props.bonusEnd}
            />
          )});

        return (
            <div className="reel">
                {slotMachineElements}
            </div>
        );
    }
}

export default SlotMachineReel;