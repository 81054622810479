import React, { useState, useEffect, useRef} from 'react';
import './SlotMachine.css';
import emojiArr from './emoji';
import bridge from '@vkontakte/vk-bridge';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { useAppearance } from '@vkontakte/vkui';

const SlotMachineElement = (props) => {
    const [emoji, setEmoji] = useState(false)
    const [divClass, setDivClass] = useState('element')
    const [showWild, setShowWild] = useState(false)
    const [wildEmoji, setWildEmoji] = useState(false)
    const [wildDivClass, setWildDivClass] = useState(false)

    const divRef = useRef(null);
    const prevPropsRef = useRef();
    const appearance = useAppearance();
    const tg = window.Telegram.WebApp


    const getRandomIntInclusive = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }

    const transitionEndHandler = () => {
        if (!props.turboSpin) {
            if (tg.initData == '') {
                bridge.send('VKWebAppTapticImpactOccurred', {
                    style: 'light'
                    })
                    .then((data) => { 
                      if (data.result) {
                        console.log("OK")
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                });
            } else {
                tg.HapticFeedback.impactOccurred('light')
            }
            
        }
        if (props.index == 4 && props.last && divClass != 'element' && divClass != 'element raise') {
            if (props.turboSpin) {
                if (tg.initData == '') {
                    bridge.send('VKWebAppTapticImpactOccurred', {
                        style: 'light'
                        })
                        .then((data) => { 
                          if (data.result) {
                            console.log("OK")
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                    });
                } else {
                    tg.HapticFeedback.impactOccurred('light')
                }
            }
            props.onData({disabled: false})
        }
      };
      

      useEffect(() => {
        const elemCoordinates = [props.index, props.elemIndex]
        const prevEmoji = prevPropsRef.current?.emoji;
        const bigLineHeights = [3888, 6048, 8208, 10368, 12528]
        if (!props.bonusEnd) {
            setShowWild(false)
        }      

        var flag = false
        if (props.animateElems !== false && props.animateElems.length > 0) {
            props.animateElems.forEach(elem => {
                if (elemCoordinates[0] == elem[0] && elemCoordinates[1] == elem[1]) {  
                    if (props.bonusSpins && (prevEmoji == 11 || prevEmoji == 12))
                    {
                        setShowWild(prevEmoji)
                        setWildDivClass((prevEmoji == 11) ? `element x2` : `element x3`)
                        setWildEmoji(<Player 
                            autoplay
                            loop
                            src={emojiArr.animations[prevEmoji]}
                            style={{ height: '72px', width: '72px' }}
                            >
                        </Player>);
                    } else {
                        setEmoji(<Player 
                            autoplay
                            loop
                            src={emojiArr.animations[props.emoji]}
                            style={{ height: '72px', width: '72px' }}
                            >
                        </Player>);
                        
                    }
                    flag = true
                }
            });
        }
        if (!flag) {
            if (props.bonusSpins && (prevEmoji == 11 || prevEmoji == 12)) {
                setEmoji(<img height="72px" src={emojiArr.static[getRandomIntInclusive(0, 10)]} />);
                setWildDivClass((prevEmoji == 11) ? `element x2` : `element x3`)
                setWildEmoji(<img height="72px" src={emojiArr.static[prevEmoji]} />)
                setShowWild(prevEmoji)
                
            } else if (!props.bigline) {
                setEmoji(<img height="72px" src={emojiArr.static[props.emoji]} />);
            } else {
                if (props.turboSpin) {
                    setEmoji(<img height="3600px" width="72px" style={{objectFit: "cover", objectPosition: "top"}} src={ appearance === 'light' ? emojiArr.bigline.light[props.index] : emojiArr.bigline.dark[props.index]} />)
                } else {
                    setEmoji(<img height={`${bigLineHeights[props.index]}px`} width="72px" style={{objectFit: "cover", objectPosition: "top"}} src={ appearance === 'light' ? emojiArr.bigline.light[props.index] : emojiArr.bigline.dark[props.index]} />)
                }
            }
            
        }
        
        if (props.spin && props.spin != prevPropsRef.current?.spin) {
            
            switch (props.emoji) {
                case 11:
                    if (props.bonusSpins && prevEmoji === 11) {
                        setShowWild(11)
                    }
                    setDivClass((!props.turboSpin) ? `element x2 raise-${props.index}` : `element x2 raise-turbo`)
                    break;
                case 12:
                    if (props.bonusSpins && prevEmoji === 12) {
                        setShowWild(12)
                    }
                    setDivClass((!props.turboSpin) ? `element x3 raise-${props.index}` : `element x3 raise-turbo`)
                    
                    break
                default:
                    setDivClass((!props.turboSpin) ? `element raise-${props.index}` : `element raise-turbo`)
                    break
            }
            setTimeout(() => {
                switch (props.emoji) {
                    case 11:
                        if (props.bonusSpins && prevEmoji === 11) {
                            setShowWild(11)
                            setEmoji(<img height="72px" src={emojiArr.static[getRandomIntInclusive(0, 10)]} />);
                            setDivClass((props.turboSpin) ? `element down-turbo` : `element down-${props.index}`);
                            break;
                        }
                        if (props.bonusSpins) {
                            setDivClass((props.turboSpin) ? `element x2 down-turbo` : `element x2 down-${props.index}`);
                        } else {
                            setDivClass((props.turboSpin) ? `element down-turbo` : `element down-${props.index}`); 
                        }
                        break;
                    case 12:
                        if (props.bonusSpins && prevEmoji === 12) {
                            setShowWild(12)
                            setEmoji(<img height="72px" src={emojiArr.static[getRandomIntInclusive(0, 10)]} />);
                            setDivClass((props.turboSpin) ? `element down-turbo` : `element down-${props.index}`);
                            break;
                        }
                        if (props.bonusSpins) {
                            setDivClass((props.turboSpin) ? `element x3 down-turbo` : `element x3 down-${props.index}`);
                        } else {
                            setDivClass((props.turboSpin) ? `element down-turbo` : `element down-${props.index}`); 
                        } 
                        break
                    default:
                        setDivClass((props.turboSpin) ? `element down-turbo` : `element down-${props.index}`);
                        break
                }
            }, 10)  
        }
        prevPropsRef.current = props;
    }, [props])
 
    return (
        <div style={{ position: 'relative' }}>
            {showWild && 
                <div className={wildDivClass} style={{position: 'absolute', left: 0, top: 0, zIndex: props.zIndex + 1}}>
                    {wildEmoji}
                </div>
            }

            <div ref={divRef} className={divClass} style={{zIndex: props.zIndex}} onTransitionEnd={transitionEndHandler}>
                {emoji}
            </div>
        </div> 
    )
}

export default SlotMachineElement;